export default {
  props: {
    index: Number,
    item: Object,
    updateBannerRequest: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imageWidth: '100%',
  }),
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    updateItem(type, instance) {
      this.$emit('updateItem', {
        index: this.index,
        type,
        instance,
        instance_id: instance.id,
      })
    },
  },
  computed: {
    imageHeight() {
      return this.cardProperties.height - 52
    },
    cardProperties() {
      return {
        class: 'mr-4 mb-4 banner-item',
        width: 200,
        height: 180,
      }
    },
    instanceType() {
      return this.item.instance_type
    },
    instance() {
      return this.item.instance
    },
  },
}
